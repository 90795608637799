<template>
<div>
  <vs-card>
    <div class="text-center mt-10 mb-10">
      <h2 class="float-center">Bonjour {{ gender(user.person.gender) }} {{ user.person.firstname }} {{ user.person.lastname }}</h2>
      <p class="aps-benin-v2-home-desc">
        Bienvenue sur votre espace assuré !
      </p>
      <div style="display: flex; justify-content: center;">
        <div style="margin-left: 40px; margin-right: 40px;">
          <vs-button class="mt-6" @click="open('SOHU')">Nos offres</vs-button>
        </div>
        <div style="margin-left: 40px; margin-right: 40px;">
          <vs-button class="mt-6" @click="$router.push({ name: 'Contrat' })">Vos contrats</vs-button>
        </div>
        <div style="margin-left: 40px; margin-right: 40px;">
          <vs-button class="mt-6" @click="$router.push({ name: 'Ticket' })">Faire une demande</vs-button>
        </div>
      </div>
      
    </div>
  </vs-card>
</div>
 
</template>

<script>
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import global from "@/config/global";

export default {
  data() {
    return {
      global: {},
    };
  },
  created() {
    this.global = global;
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("general", ["allProduct"]),
  },
  methods: {
    getProduct(productId) {
      return this.allProduct.find(product => product.id == productId).label;
    },
    open(productID) {
      let url = `${this.global.tunnelHost}/landing/${productID.toLowerCase()}?type=${this.user.type}&id=${this.user.id}&productID=${productID}&fromOrigin=direct`
      window.open(url, "_blank");
    },
    gender(a) {
      let list = { "M": "Monsieur", "F": "Madame" }
      if(a) {
        return list[a]
      } else {
        return ""
      }
    }
  }
};
</script>

<style>
.aps-benin-v2-home-desc {
  font-size: large;
  margin-left: 10vw;
  margin-right: 10vw;
}
</style>
